<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <div class="withdrawal-content">
            <el-tabs v-model="tabAc">
                <el-tab-pane label="用户服务协议" name="yhfw">
                    <Service></Service>
                </el-tab-pane>
                <el-tab-pane label="隐私政策" name="ysxy">
                    <Privacy></Privacy>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import Service from './child/service.vue'
import Privacy from './child/privacy.vue'
export default {
    data() {
        return {
            tabAc: "yhfw"
        };
    },
    mounted() {
        this.tabAc = this.$route.query.type;
    },
    components: { Service, Privacy }
}
</script>

<style lang="scss" scoped>
.withdrawal-content{
    height: 100%;
    overflow-y: auto;
    background: #ffffff;
    font-size: 14px;
    padding: 24px 32px
}
/deep/ .el-tabs__item {
    // color: #007aff;
    font-size: 14px;
}

/deep/ .el-tabs__item.is-active {
    color: #007AFF;
    font-weight: bold;
}

/deep/ .el-tabs__nav-wrap::after {
    background: none !important;
}
</style>